import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const About = () => {
    return (
      <Container>
      <Row>
        <Col>        
        <h1>About Goअमृत Dairy</h1>
        <p> Goअमृत Dairy is a inesiative of providing fresh milk, Vermicompost, fresh vegitable, Organic Vegitable to the pepole.</p>       
        </Col>
      </Row>
    </Container>     
    );
  };  
  export default About;