import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import team1 from '../img/team1.jpg';
import team2 from '../img/team2.jpg';
import team3 from '../img/team3.jpg';
const Team = () => {
    return  (
    <Container>
        <Row><h1 class="mb-5">Experienced Team Members</h1></Row>
        <Row>
      <Col>        
      <Card.Img src={team1} style={{width:'14rem', height:'11rem'}}/>
      <h5>Saurabh</h5>
      <p class="text-primary">Founder(Veterinarian)</p>
      </Col>
      <Col>        
      <Card.Img src={team2} style={{width:'14rem', height:'11rem'}}/>
      <h5>Vikash</h5>
      <p class="text-primary">Founder(Farmer)</p>
            </Col>
            <Col>        
            <Card.Img src={team3} style={{width:'14rem', height:'11rem'}}/>
            <h5>Manish</h5>
            <p class="text-primary">Founder(Contractor)</p>
            </Col>
    </Row>
    
  </Container>  )  
  };
  
  export default Team;