import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./components/home";
import About from "./components/about";
import Admin from "./components/admin";
import Contact from "./components/contacts";
import Product from "./components/product";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Team from "./components/team";
import 'bootstrap/dist/css/bootstrap.min.css';



export default function App() {
  return (
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="admin" element={<Admin />} />
          <Route path="about" element={<About/>} />
          <Route path="contacts" element={<Contact />} />   
          <Route path="product" element={<Product />} /> 
          <Route path="services" element={<Services />} /> 
          <Route path="gallery" element={<Gallery />} />   
          <Route path="team" element={<Team />} />   
        </Route>
      </Routes>
    </BrowserRouter>
  );
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>  
    <App/>   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
