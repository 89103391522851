import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Admin = () => {
    return  (
    <Container>
    <Row>
      <Col>        
      <h1>Admin</h1>
      <p>Comming soon..</p>       
      </Col>
    </Row>
  </Container>  )  
  };
  
  export default Admin;