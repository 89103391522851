import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Card from 'react-bootstrap/Card';
import logo from '../img/logo.jpg';
import Carousel from 'react-bootstrap/Carousel';
import CarouselImage1 from '../img/carouselImage1.jpg';
import CarouselImage2 from '../img/carouselImage2.jpg';
import CarouselImage3 from '../img/carouselImage3.jpg';


const Layout = () => {
  return (
    <Container>
      <Card.Header style={{ height:'10%'}}>
      <Row>
      <Col><Card.Img src={logo} style={{width:'13rem', height:'3rem'}}/></Col>
      <Col></Col>
        <Col>        
        <Navbar expand="lg" className="bg-body-tertiary">
      <Container>        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contacts">Contacts</Nav.Link>
              <Nav.Link href="/gallery">Gallery </Nav.Link>
              <Nav.Link href="/product">Product</Nav.Link>
              <Nav.Link href="/team">Team</Nav.Link>
            <NavDropdown title="Admin" id="basic-nav-dropdown">              
              <NavDropdown.Item href="/services">Services</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/admin">Admin</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>   
    </Col>
      </Row>
      </Card.Header>
      <Card.Body style={{width:'100%',height:'80%'}}><Row><Col>
      <Carousel>
      <Carousel.Item>
      <img  className="d-block w-100"  src={CarouselImage1} style={{height:'40rem'}} alt="First slide"  />        
        <Carousel.Caption>
          <h3>First slide label</h3>          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className="d-block w-100"  src={CarouselImage2} style={{height:'40rem'}}  alt="Second slide"  /> 
        <Carousel.Caption>
          <h3>Second slide label</h3>          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className="d-block w-100"  src={CarouselImage3} style={{height:'40rem'}}  alt="Third slide"  /> 
        <Carousel.Caption>
          <h3>Third slide label</h3>         
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>      
      <Outlet /></Col></Row></Card.Body>
      <Card.Footer style={{ height:'10%'}}><Row><Col>&copy; <a href="#">Goअमृत</a>, All Right Reserved.  Designed By <a href="#">Goअमृत</a></Col></Row></Card.Footer>
     </Container>    
  )
};

export default Layout;