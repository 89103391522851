import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const Contacts = () => {
    return  (
      <Container>
          <Row></Row>
          <Row>
            <Col>          
                    <h2 >Contact Details</h2>                      
                            <h4>Our Office</h4>
                            <span>Poari, Harnaut, Nalanda Bihar, India</span>                       
                            <h4>Call Us</h4>
                            <span>+91 00000000</span>                 
                            <h4>Mail Us</h4>
                            <span>info@goamrit.com</span>                        
                                            
            </Col>
            <Col> 
            <iframe class="w-100 rounded" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d428.4007430545896!2d85.5942474933105!3d25.350916916247282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1705342199086!5m2!1sen!2sin" target="_parent"  frameborder="0"  allowfullscreen="" aria-hidden="false"  style={{width:'100%',height:'20rem'}} tabindex="0"></iframe>
            </Col>
      </Row>
      
    </Container>  )
  };
  
  export default Contacts;